import { AppRoutes } from "../../routes";

export const PaymentsMenu =[
  {
    title: 'Órdenes',
    url: `/${AppRoutes.platform.orders.route}`,
    icon: 'assets/recicladora/income.svg',
  },
  {
    title: 'Control de gastos',
    url: `/${AppRoutes.platform.expenses.route}`,
    icon: 'assets/recicladora/expenses.svg',
  },
  {
    title: 'Reportes',
    children: [
      { title: 'Salidas', url: `/${AppRoutes.platform.reports.outflow.route}` },
      { title: 'Ingresos', url: `/${AppRoutes.platform.reports.purchase.route}` }
    ],
    icon: 'assets/recicladora/reports.svg',
    open: false,
  },
];
