export const AppRoutes = {
    default: "login",
    auth: {
      login:{
        route:"login",
        main:"login",
      }
    },
    get platform() {
      return platform;
    }
}

const platform = ({
  route:"platform",
  main:"platform",
  get operatorView(){
    return ({
      route:`${this.route}/operator-view`,
      main:"operator-view",
    })
  },
  get users(){
    return ({
      route:`${this.route}/users`,
      main:"users",
    })
  },
  get orders(){
    return ({
      route:`${this.route}/orders`,
      main:"orders",
    })
  },
  get incomes(){
    return ({
      route:`${this.route}/incomes`,
      main:"incomes",
      get create() {
        return {
          route: `${this.route}/create`,
          main: 'create',
        }
      },
      get paused() {
        return {
          route: `${this.route}/paused`,
          main: 'paused',
        }
      },
      get edit() {
        return {
          route(id:number){
            return this._route.replace(":id", id);
          },
          _route: `${this.route}/edit/:id`,
          main: 'edit/:id',
        }
      }
    })
  },
  get outFlows () {
    return ({
      route: `${this.route}/outflows`,
      main: 'outflows',
      get register() {
        return {
          route: `${this.route}/register`,
          main: 'register',
        }
      }
    })
  },
  get inventory(){
    return ({
      route:`${this.route}/inventory`,
      main:"inventory",
    })
  },
  get expenses(){
    return ({
      route:`${this.route}/expenses`,
      main:"expenses",
      get create() {
        return {
          route: `${this.route}/create`,
          main: 'create',
        }
      },
      get list() {
        return {
          route: `${this.route}/list`,
          main: 'list',
        }
      }
    })
  },
  get categories(){
    return ({
      route:`${this.route}/categories`,
      main:"categories",
    })
  },
  get dashboard(){
    return ({
      route:`${this.route}/dashboard`,
      main:"dashboard",
    })
  },
  get reports () {
    return ({
      route: `${this.route}/reports`,
      main: 'reports',
      get outflow() {
        return {
          route: `${this.route}/outflow`,
          main: 'outflow',
        }
      },
      get purchase() {
        return {
          route: `${this.route}/purchase`,
          main: 'purchase',
        }
      }
    })
  },
})


