import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() name: string = ''
  @Input() nameRole: string = ''
  @Input() idMenu: string = ''
  constructor(private menu: MenuController) { }

  ngOnInit() {}

  async openMenu () {
    /* console.log(this.idMenu)
    this.menu.enable(true, this.idMenu);
    this.menu.open(this.idMenu); */
    await this.menu.open();
  }

}
