import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/abstractComponents/base.component';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-operator-first-page',
  templateUrl: './operator-first-page.component.html',
  styleUrls: ['./operator-first-page.component.scss'],
})
export class OperatorFirstPageComponent extends BaseComponent implements OnInit {

  constructor(public authService:AuthService, public router:Router) {
    super();
   }

  ngOnInit() {}

  goIncoming(){
    let userData = this.authService.userLocalStorage;
    userData.operatorView = false;
    this.authService.setUserData(userData);
    this.router.navigateByUrl(this.getAppRoutes.platform.incomes.route).then(res=> location.reload());
  }

  goOutflow(){
    let userData = this.authService.userLocalStorage;
    userData.operatorView = false;
    this.authService.setUserData(userData);
    this.router.navigateByUrl(this.getAppRoutes.platform.outFlows.route).then(res=> location.reload());
  }

}
