const endpoints = {
  rol:{
    get_all: "/role",
  },
  auth:{
    login: "/auth/login",
  },
  user:{
    get_all: "/user?limit=10&sort=createdAt DESC",
    get_all_pagination: '/user?limit=10&skip=:skip&sort=createdAt DESC',
    post: "/auth/register",
    _patch: "/user/:id",
    patch(id:string|number){
      return this._patch.replace(":id", id);
    },
    delete(id:string|number){
      return this._delete.replace(":id", id);
    },
    _delete: "/user/:id",
  },
  orders:{
    get_all: `/order?limit=10&where={"status":["pagado", "pendiente"]}&sort=createdAt DESC`,
    get_all_pagination: '/order?limit=10&where={"status":["pagado", "pendiente"]}&skip=:skip&sort=createdAt DESC',
    get_paused: "/order?status=pausado&limit=10&sort=createdAt DESC",
    get_paused_pagination: "/order?status=pausado&limit=10&skip=:skip&sort=createdAt DESC",
    delete: "/order/:id",
    patch: "/order/:id",
    get_pendiente: "/order?status=pendiente",
    post: '/order',
    get_id: "/order/",
    get_report: "/reports/orders",
    post_weight: '/api/captura_data_pesa',
    get_paid: '/order?status=pagado&limit=10&sort=createdAt DESC',
    get_paid_pagination: '/order?status=pagado&limit=10&skip=:skip&sort=createdAt DESC',
    get_all_search: "/order",
    send_report: "/reports/email",
    get_paid_search: '/order?limit=10&sort=createdAt DESC',
    get_all_date:"/order?limit=10&sort=createdAt DESC",
    get_all_date_report: "/order?limit=-1&sort=createdAt DESC",
  },
  outflow:{
    get_all:"/outflow?limit=10&sort=createdAt DESC",
    get_all_date:"/outflow?limit=10&sort=createdAt DESC",
    get_all_date_report:"/outflow?limit=-1&sort=createdAt DESC",
    get_all_search: "/outflow",
    get_all_pagination: "/outflow?limit=10&skip=:skip&sort=createdAt DESC",
    send_report: "/reports/email"
  },
  inventory:{
    get_all: "/inventory?limit=10&sort=updatedAt DESC",
    get_to_be_processed:'/inventory?where={ "toBeProcessed": { ">" : "0" }}&limit=-1&sort=updatedAt DESC',
    _patch: "/inventory/:id",
    patch(id:string|number){
      return this._patch.replace(":id", id);
    },
    delete(id:string|number){
      return this._delete.replace(":id", id);
    },
    _delete: "/inventory/:id",
    get_all_pagination:"/inventory?limit=10&skip=:skip&sort=updatedAt DESC",
  },
  products: {
    get_all: '/products?where={ "deletedAt":  null }',
    patch(id:string|number){
      return this._patch.replace(":id", id);
    },
    _patch: "/products/:id",
    delete(id:string|number){
      return this._delete.replace(":id", id);
    },
    _delete: "/products/:id",
  },
  devices: {
    get_all: '/device',
    get_device: '/device/',
    get_all_project: '/project',
  },
  ticket:{
    get_bundles_by_ticket:"/ticket/:id/bundles/",
    get_id: '/ticket/',
    get_bulk_material_by_ticket: '/soldbulkmaterial?ticketId='
  },
  bundle:{
    get_id: "/bundles/",
    get_where: '/bundles?where={ "status": { "!=": "selled" }, "productId": :id }&limit=10&sort=createdAt DESC',
    get_where_pagination: '/bundles?where={ "status": { "!=": "selled" }, "productId": :id }&limit=10&skip=:skip&sort=createdAt DESC',
    get_processed:"/bundles?status=processed&limit=10&sort=updatedAt DESC",
    get_processed_pagination:"/bundles?status=processed&limit=10&skip=:skip&sort=updatedAt DESC",
    get_to_be_processed:"/bundles?status=toBeProcessed&limit=10&sort=updatedAt DESC",
    get_to_be_processed_pagination:"/bundles?status=toBeProcessed&limit=10&skip=:skip&sort=updatedAt DESC",
    post_bundles_processing:"/bundles/processing",
    get_bundles_processed_by_product:"/bundles?status=processed&productId=:idProduct&limit=10&sort=updatedAt DESC",
    get_validation_invoice: "/validate/invoicenumber/"
  },
  expenses: {
    get_all: '/expense',
    get_expense_categories: '/expenseCategory',
    post: '/expense',
    send_report: '/reports/email',
    patch(id:string|number){
      return this._patch.replace(":id", id);
    },
    _patch: "/expense/:id",
    delete(id:string|number){
      return this._delete.replace(":id", id);
    },
    _delete: "/expense/:id",
  },
  categories: {
    get_all: '/products?limit=10&where={ "deletedAt":  null }&sort=updatedAt DESC',
    get_all_pagination: '/products?limit=10&skip=:skip&where={ "deletedAt":  null }&sort=updatedAt DESC',
    post: '/products'
  },
  outflows : {
    post: '/outflow'
  },
  dashboard: {
    get_all: '/dashboard',
    get_expenses_filters: '/expense-manager',
    _get_expenses: '/expense-manager?month=:month',
    get_expenses(month:string){
      return this._get_expenses.replace(":month", month);
    },
  },
  subsidiary: {
    get_all: '/subsidiary'
  }
};
export default endpoints;
