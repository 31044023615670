import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/abstractComponents/base.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends BaseComponent implements OnInit {


  public ss = [
    {
      title: 'Ingresos',
      url: `/${this.getAppRoutes.platform.incomes.route}`,
      icon: 'income.svg',
    },
    {
      title: 'Salidas',
      url: `/${this.getAppRoutes.platform.outFlows.route}`,
      icon: 'outflows.svg',
    },
    {
      title: 'Pendientes',
      url: '/folder/Favorites',
      icon: 'pendings.svg',
    },
    {
      title: 'Control de gastos',
      url: `/${this.getAppRoutes.platform.expenses.route}`,
      icon: 'expenses.svg',
    },
    {
      title: 'Inventario',
      url: `/${this.getAppRoutes.platform.inventory.route}`,
      icon: 'inventory.svg',
    },
    {
      title: 'Reportes',
      children: [
        { title: 'Salidas', url: '/operator/pendings' },
        { title: 'Compras', url: '/folder/Outbox' },
      ],
      icon: 'inventory.svg',
      open: false,
    },
  ];

  constructor() {
    super();
  }

  ngOnInit() {}
}
