import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private apiURL:string;
  private apiIOT:string;


  constructor(private http: HttpClient) {
    this.apiURL =  environment.API_URL
    this.apiIOT = environment.API_IOT
  }
  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/
  tail:Array<any> = [];

  _token:any;

  get token() {
    if(!this._token) this._token = localStorage.getItem('token');
    return this._token;
  }

  set token(value) {
    this._token = value;
  }

  public get(url:string) {
    return this.request(this.http.get(this.apiURL+url));
  }

  public postWeightIOT(url:string, data) {
    return this.request(this.http.post(this.apiIOT+url, data));
  }

  public getBlob(url:string) :Observable<Blob> {
    const header:any = { observe: 'response', responseType: "blob" }
    return this.request(this.http.get<any>(this.apiURL+url, header))
  }

  public post(url:string, data:any){
    return this.request(this.http.post(this.apiURL+url, data));
  }

  public put(url:string, data:any){
    data._method = 'put';
    return this.request(this.http.put(this.apiURL+url, data));
  }

  public patch(url:string, data:any){
    //data._method = 'patch';
    return this.request(this.http.patch(this.apiURL+url, data));
  }

  public delete(url:string){
    return this.request(this.http.delete(this.apiURL+url));
  }

  private request(a:Observable<any>){
    this.tail.push(a);
    return a.pipe(
      retry(1),
      catchError(this.handleError),
      finalize(() => {
        let index = this.tail.indexOf(a);
        this.tail.splice(index, 1);
      })
    );
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error?.error?.code === 'E_UNIQUE') {
      errorMessage = error.error
      return throwError(errorMessage);
    } else if (error?.error?.message) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.status===403 || error.status===401){
      Swal.fire({
        title: 'Mensaje',
        text: "Sesión Expirada",
        icon: 'error',
        confirmButtonText: 'Ok'
      }).then(()=>{
        localStorage.clear();
        window.location.reload();
      })
    } else if (error.status === 404) {
      return throwError(error)
    } else{
      Swal.fire({
        title: 'Mensaje',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }

    return throwError(errorMessage);
  }
}
