import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './core/api.service';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import endpoints from './core/endpoints';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserRol } from 'src/app/app.component';
import { User } from '../interfaces/User';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiService {

  private _getLoggedUser: BehaviorSubject<any> = new BehaviorSubject<any>((<any>this.userLocalStorage));
  public getLoggedUser$: Observable<any> = this._getLoggedUser.asObservable();

  get userLocalStorage(){
    return  JSON.parse(localStorage.getItem("userLogged"));
  }

  constructor(http:HttpClient, public router:Router) {
    super(http);
   }

  login(params:any){
    const { auth : {login : url} } = endpoints
    return this.post(url, params).pipe(
      map((data:any) => {
        localStorage.setItem('token', data.token);
        localStorage.setItem('userLogged', JSON.stringify(data.user));
        if (data?.user?.device) localStorage.setItem('deviceId', data.user.device)
        this.setUserData(data.user);
        return data.user;
      }),
      switchMap((data:any) => {
        this.firstView(data)
        return this.getLoggedUser$;
      })
    );
  }

  firstView(data:User){
    if(data.role.name === UserRol.operator){
      data["operatorView"] = true;
      this.setUserData(data);
    }else{
      this.setUserData(data);
    }
  }

  setUserData(data:User){
    this._getLoggedUser.next(data);
  }
}
