import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from './platform/shared/abstractComponents/base.component';
import { User } from './platform/shared/interfaces/User';
import { AuthService } from './platform/shared/services/auth.service';
import { adminMenu } from './platform/template/menus/admin-menu';
import { OperatorMenu } from './platform/template/menus/operator-menu';
import { PaymentsMenu } from './platform/template/menus/payments-menu';

export enum UserRol {
  admin = "Administrador",
  operator = "Operador",
  payment = "Caja",
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {

  public appPages =  [];
  public userData:User = {} as User;

  constructor(public authService:AuthService, public router:Router) {
    super()
  }

  ngOnInit(): void {
      this.authService.getLoggedUser$.subscribe((user:User)=>{
        this.appPages = this.getMenu(user);
        this.userData = {...user};
      })
  }

  showOperationView() :boolean{
    return this.userData?.operatorView
  }

  getMenu(user:User){
    switch (user?.role?.name) {
      case UserRol.admin:
        return adminMenu;
      case UserRol.operator:
        return OperatorMenu;
      case UserRol.payment:
        return PaymentsMenu;
      default:
        break;
    }
  }

  logout(){
    localStorage.clear();
    location.reload();
  }
}
