import { AppRoutes } from "../../routes";

export const OperatorMenu =[
  {
    title: 'Ingresos',
    url: `/${AppRoutes.platform.incomes.route}`,
    icon: 'assets/recicladora/income.svg',
  },
  {
    title: 'Salidas',
    url: `/${AppRoutes.platform.outFlows.register.route}`,
    icon: 'assets/recicladora/outflows.svg',
  },
  {
    title: 'Pendientes',
    url: `/${AppRoutes.platform.incomes.paused.route}`,
    icon: 'assets/recicladora/pendings.svg',
  },
  {
    title: 'Control de gastos',
    url: `/${AppRoutes.platform.expenses.create.route}`,
    icon: 'assets/recicladora/expenses.svg',
  },
  {
    title: 'Inventario',
    url: `/${AppRoutes.platform.inventory.route}`,
    icon: 'assets/recicladora/inventory.svg',
  },
  {
    title: 'Reportes',
    children: [
      { title: 'Salidas', url: `/${AppRoutes.platform.reports.outflow.route}` },
      { title: 'Ingresos', url: `/${AppRoutes.platform.reports.purchase.route}` }
    ],
    icon: 'assets/recicladora/reports.svg',
    open: false,
  },
];
