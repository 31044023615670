import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { TableComponent } from './table/table.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { CardComponent } from './card/card.component';
import { FormsModule } from '@angular/forms';
import { OperatorFirstPageComponent } from './operator-first-page/operator-first-page.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';


const components = [NavbarComponent, TableComponent, SidebarComponent, CardComponent, OperatorFirstPageComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PaginationModule
  ]
})
export class TemplateModule { }
