import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PageChangedEvent, PagerComponent } from 'ngx-bootstrap/pagination';

interface ColumnTotal {
  showColumn: boolean,
  textHeader: string,
  totalValue: number
}

interface Actions {
  text: string,
  type: string,
  classStyle: string
}

interface columns {
  name: string,
  prop: string,
  ngClass?: any
  transform?: any
  checkbox?: any
  buttons?: button[]
  html?:any;
}

interface button{
  label:string;
  class:string;
  event:(data)=>{};
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableComponent implements OnInit {
  currentPage = 1;
  @Input() dataColumnTotal: ColumnTotal = {showColumn: false, textHeader: '', totalValue: 0}
  @Input() actionsTable: Actions [] = [];
  @Input() columns: columns[] = []
  @Input() rows = []
  @Input() refreshTable: boolean = false;
  @Input() resetPagination: boolean = false;
  @Output() clickedRow = new EventEmitter();
  @Input() itemsPerPage: number = 10;
  @Input() totalItems: number = 0;
  isRestPagination:boolean = false
  temp = [];
  constructor() {
  }

  ngOnInit() {
    this.startDataTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshTable) {
        if (changes.refreshTable.currentValue) {
            this.updateTable();
            this.refreshTable = false;
        }
    } else if (changes.resetPagination) {
      this.currentPage = 1
      this.isRestPagination = true
      setTimeout(() => {
        this.isRestPagination = false;
      }, 1000);
    }
  }

  startDataTable () {
    const arrColumns = this.columns.map(col => col.prop)
    if (this.rows) {
      this.temp = this.rows.map((prop) => {
        const data = arrColumns.map(nameCol => {
          const splited = nameCol.split('.');
          let temValue = "";
          if(splited.length){
            for (let index = 0; index < splited.length; index++) {
              const element = splited[index];
              if(temValue) {
                if (temValue[element] !== null && temValue[element] !== ''  && temValue[element]!==undefined) {
                  temValue = temValue[element]
                }
              }
              if (!temValue && (prop[element] !== null && prop[element] !== ''  && prop[element]!==undefined)) {
                temValue = prop[element]
              }
            }
          }
          return temValue
        })
        return data;
      });
    }
  }

  updateTable() {
    this.startDataTable();
  }
  clickedRowEmit(row){
    this.clickedRow.emit({...row});
  }

  clickedActionEmit(row, type){
    this.clickedRow.emit({ data: {...row}, action: type} );
  }

  transform(value, transformer?){
    if(transformer){
      return transformer(value);
    }
    return value
  }

  pageChanged(event: PageChangedEvent): void {
    if (!this.isRestPagination) this.clickedRow.emit({ skip: (event.page-1)*event.itemsPerPage, action: 'change-page'} );
  }
}
